.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.background:hover {
  cursor: pointer;
}

.modal-wrapper {
  padding-top: 50px;
  width: 950px;
  height: 500px;
  color: #000;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 999999;
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.modal-img {
  width: 100%;
  height: 500px;
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  transition: 0.5s ease;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-modal {
  max-width: 100%;
  height: 500px;
  object-fit: cover;
}

.p-vermas {
  color: #009340;
  font-size: 12px !important;
  font-weight: 600;
}

.close-modal {
  cursor: pointer;
  top: 20px;
  right: 30px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
  color: #ddd !important;
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  display: none;
}

@media only screen and (max-width: 968px) {
  .background {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-img {
    max-width: 100%;
    height: 310px !important;
    background: #000;
    -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .img-modal {
    max-width: 100% !important;
    height: 310px !important;
  }

  .modal-wrapper {
    padding-top: 100px;
    padding-left: 100px;
    padding-right: 100px;
    width: 950px;
    height: 350px;
    color: #000;
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    z-index: 999999;
    -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }

  .close-modal {
    cursor: pointer;
    float: left;
    right: 30px;
    padding: 0;
    z-index: 1;
    color: #ddd !important;
    -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    display: none;
  }
}

@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
