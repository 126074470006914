.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
}

.linkpage-item {
  background: #28a745 !important;
  color: white !important;
  font-size: 15px !important;
}

.linkpage-item:hover {
  cursor: pointer !important;
  background: white !important;
  color: #28a745 !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.Active-Pag {
  background: white !important;
  color: #28a745 !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-weight: 600;
}

.pagina-actual {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  color: #28a745;
  font-weight: 600;
  font-size: 15px;
}

@media only screen and (max-width: 968px) {
    .pagination-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
      }

      .pagina-actual {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -5px;
        color: #28a745;
        font-weight: 600;
        font-size: 15px;
        margin-bottom: -25px;
      }
}
