@font-face {
  font-family: "LuzColor";
  src: local("LuzColor"),
    url(../../../public/fonts/BillionDreams_PERSONAL.ttf) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

.main-footer {
  background: #1b1b1b;
  width: 100%;
  padding: 35px 30px 10px 30px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
}

.contenedor {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Luz {
  font-family: LuzColor;
  color: #ddd !important;
  font-size: 35px;
  margin-bottom: -10px;
}

.left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-footer {
  height: 60px;
  width: 50px;
  margin-right: 15px;
}

.center p {
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -50px;
  font-size: 12px;
  text-align: center;
}

.right a {
  color: #ddd !important;
  text-decoration: none;
  font-size: 12px;
}

.right {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin-bottom: -40px;
}
.enlaces {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  margin-left: -20px;
}

.aside-others-a {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 35%;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  width: 100%;
  padding-bottom: 35px;
  gap: 1px;
}

.by {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  margin-top: 15px;
}

.linked-aside:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media all and (max-width: 968px) {
  .contenedor {
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }
  .main-footer {
    background: #1b1b1b;
    width: 100%;
    padding: 10px 10px 10px 10px;
    margin-bottom: 0px;
  }

  .img-footer {
    height: 35px;
    width: 30px;
    margin-right: 10px;
  }

  .Luz {
    font-family: LuzColor;
    color: #ddd !important;
    font-size: 25px;
  }

  .enlaces {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    margin-left: -20px;
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .center p {
    color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    margin-top: 30px;
  }

  .aside-others-a {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 10px;
    width: 100%;
    padding-bottom: 10px;
    gap: 2px 2px;
    text-align: center;
  }

  .linked-aside:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .by {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 12px;
    margin-top: 15px;
  }
}
