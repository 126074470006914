.contenedor-producto {
  width: 100%;
  height: 100%;
}

/* clases de estilo para el producto */
.header-producto {
  width: 100%;
  height: 325px;
  background: rgb(40, 167, 69);
  border-bottom-left-radius: 60px;
  border-bottom-right-radius: 60px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding-bottom: 100px;
  margin-bottom: 20px !important;
}
/* Barra de regresar */
.tool-header {
  width: 100%;
  padding-top: 120px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tool-header a {
  text-decoration: none;
  color: white;
  background: black;
  width: 120px;
  text-align: center;
  padding: 10px;
  font-weight: 600;
  border-radius: 30px;
  transition: ease-in 0.3s;
}

.tool-header a:hover {
  cursor: pointer;
  color: black;
  background: white;
}
/* Contenerdor del producto */
.producto-all {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 80px;
}

::selection {
  color: white;
  background: #28a745;
}

.producto {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column-reverse !important;
  align-items: center;
  margin-top: -200px;
  margin-left: 30px;
}
.producto img {
  width: 320px;
  height: 100%;
  transition: ease-in all 0.5s;
}

.img {
  background: white;
  border-radius: 100%;
  padding: 25px;
  margin-bottom: 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.producto h1 {
  color: #fff;
  font-weight: 600;
  font-size: 25px;
  margin-top: -25px;
  margin-bottom: 15px;
  text-align: center;
}

.producto-2 {
  display: flex;
  flex-direction: column;
  background: white !important;
  border-radius: 30px;
  margin-top: -300px;
  padding: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-left: 30px;
  margin-right: 30px;
}

.producto-2 h1 {
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: bold;
}

.producto-2 p {
  width: 100%;
  font-weight: 500;
  color: #808080;
  font-size: 15px;
  letter-spacing: .2px;
  line-height: 25px;
}

/* Extras del producto */
.things-product {
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #808080;
  padding: 50px;
  margin-top: 10px;
}

.things-product p {
  color: white;
  font-weight: bolder;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
}

.Fichas {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.Fichas a {
  text-decoration: none;
  color: #28a745;
  background: white;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 30px;
  text-align: center;
  border-radius: 30px;
  font-size: 15px;
  font-weight: 600;
  width: 250px;
  transition: ease-in 0.3s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.Fichas a:hover {
  cursor: pointer;
  background: #28a745;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.rel-products {
  padding-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.rel-products h1 {
  font-size: 35px;
  color: #28a745;
  font-weight: bolder;
}

.enbase {
  font-size: 15px !important;
  font-weight: 400;
  color: #808080;
  margin-bottom: 10px;
}

.related-products-md {
  padding-top: 75px !important;
  width: 100%;
  display: none;
  margin-top: -30px;
}

.related-products {
  padding-top: 75px !important;
  width: 100%;
  height: 100%;
  margin-top: -30px;
}

.not-rel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  flex-direction: column;
  margin-top: 30px;
}

.not-rel h1 {
  font-size: 30px !important;
  color: white;
  font-weight: 400;
  background: #808080;
  padding: 15px;
  border-radius: 30px !important;
}

.not-rel p {
  color: #808080;
  font-size: 15px;
  font-weight: 400;
}

.not-content {
  color: red;
  font-size: 30px !important;
  margin-top: -15px;
}

@media all and (max-width: 968px) {
  .header-producto {
    width: 100%;
    height: 325px;
    background: rgb(40, 167, 69);
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding-bottom: 100px;
  }

  .contenedor-producto {
    width: 100%;
    height: 100%;
  }

  .tool-header {
    width: 100%;
    padding-top: 110px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .producto {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: -175px;
    padding-bottom: 50px;
    margin-left: 0px;
  }

  .producto h1 {
    color: white;
    font-weight: 700;
    font-size: 20px;
    margin-top: -20px;
    margin-bottom: 10px;
  }

  .producto img {
    width: 250px !important;
    height: 100%;
    transition: ease-in all 0.5s;
  }

  .img {
    background: white;
    border-radius: 100%;
    padding: 25px;
    margin-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .producto-all {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 0px;
    padding-left: 0 !important;
    margin-top: -50px;
  }

  .producto-2 {
    background: white !important;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    margin-top: -25px;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .related-products {
    padding-top: 50px;
    width: 100%;
    display: none;
  }

  .related-products-md {
    padding-top: 50px;
    width: 100%;
    display: block;
  }

  .not-rel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    flex-direction: column;
    margin-top: 5px !important;
  }

  .not-rel h1 {
    font-size: 16px !important;
    color: white;
    font-weight: 400;
    background: #808080;
    padding: 15px;
    border-radius: 30px !important;
    text-align: center !important;
  }

  .not-rel p {
    color: #808080;
    font-size: 10px;
    font-weight: 400;
    text-align: center !important;
  }

  .not-content {
    color: red;
    font-size: 15px !important;
    margin-top: -15px;
  }

  .rel-products {
    padding-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
  .rel-products h1 {
    font-size: 35px;
    color: #28a745;
    font-weight: bolder;
  }
}
