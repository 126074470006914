.top-to-btm {
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  .icon-position {
    position: fixed;
    bottom: 30px;
    right: 25px;
    z-index: 20;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  .icon-style {
    background-color: #1b1b1b;
    border: 2px solid white;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    color: white;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all 0.5s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  .icon-style:hover {
    animation: none;
    background: #fff;
    color: #009340;
    border: 2px solid #009340;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  @keyframes movebtn {
    0% {
      transform: translateY(0px);
    }
    25% {
      transform: translateY(20px);
    }
    50% {
      transform: translateY(0px);
    }
    75% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0px);
    }
  }