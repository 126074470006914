.container-modal-pic {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.account-photo-modal {
  height: 8.5rem;
  width: 8.5rem;
  border-radius: 100%;
  user-select: none;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.container-modal-pic p {
  margin-top: 0.5rem;
  font-size: 15px;
  color: #272727;
  font-weight: 600;
}

.buttons-modal-pic {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.save-btn {
  border-radius: 60px !important;
  color: white !important;
  padding-left: 15px;
  padding-right: 15px;
  transition: ease-in-out 0.5s;
  font-size: 15px;
}

.save-btn:hover {
  color: #28a745 !important;
  background: white !important;
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.advertice {
  font-size: 10px !important;
  color: #808080 !important;
  font-weight: 400 !important;
  text-align: center !important;
}

.uploading{
  font-size: 10px !important;
}

.uploadingPortada{
  color: #272727 !important;
  font-weight: 600 !important;
  font-size: 10px !important;
}

@media all and (max-width: 968px) {
  .container-modal-pic {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }

  .save-btn {
    border-radius: 60px !important;
    color: white !important;
    padding-left: 15px;
    padding-right: 15px;
    transition: ease-in-out 0.5s;
    font-size: 12px;
  }
}
