.container-Account {
  width: 100%;
  height: 100vh;
  padding-top: 8rem;
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: 100%;
  justify-content: center;
  margin-bottom: 2.5rem;
}

.card-account {
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-left: 0.5rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 2rem;
  margin-bottom: 2rem;
}

.header-card-account {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  background-position: "center";
  background-repeat: "no-repeat";
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 10rem;
  position: relative;
}

.profilep-div {
  position: relative;
  z-index: 0;
}

.btn-change-photoP {
  position: absolute;
  top: 85px;
  right: 0px;
  cursor: pointer;
  background: #eee;
  border-radius: 100%;
  padding: 0.2rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.btn-change-photoP:hover {
  background: #ddd !important;
}

.btn-change-photoPortada {
  position: absolute;
  top: 120px;
  left: 10px;
  cursor: pointer;
  background: #eee;
  border-radius: 100%;
  padding: 0.2rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.btn-change-photoPortada:hover {
  background: #ddd !important;
}

.account-photo {
  height: 6.5rem;
  width: 6.5rem;
  margin-bottom: -6rem;
  pointer-events: none;
  user-select: none;
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.body-card-account {
  margin-top: 1.4rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.1px;
}

.body-card-account h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #272727;
  font-weight: 600;
  margin-top: 10px;
  cursor: pointer;
}

.body-card-account h1 span {
  font-size: 12px;
  font-weight: bold;
  color: red;
  margin-left: 10px;
  text-decoration: underline;
}

.body-card-account p {
  font-size: 14px;
  color: grey;
  top: 2px;
  margin-top: -0.8rem;
  text-align: center;
}

.footer-card-account {
  background: #eee;
  margin-top: 0.2rem;
  width: 100%;
  height: 4rem;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}

.fecha-account {
  margin-top: -0.8rem;
  color: #272727;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 12px;
}

.dates-account {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
}

.createdAt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.last-login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.services-account {
  color: gray;
  font-size: 10px;
  margin-bottom: -0.1rem;
}

/* menu de opciones para el cambio de foto de perfil */
.options-menu-Pprofile {
  position: absolute;
  padding: 0.5rem;
  background: #fff;
  width: 180px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  left: 80px;
  top: 120px;
  z-index: 1;
  display: block;
}

.options-menu-Pportada {
  position: absolute;
  padding: 0.5rem;
  background: #fff;
  width: 180px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  left: 12px;
  top: 158px;
  z-index: 1;
  display: block;
}

.options-menu-Pprofile .optionphoto {
  display: flex;
  height: 35px;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 10px;
}

.options-menu-Pportada .optionphoto {
  display: flex;
  height: 35px;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 10px;
}

.optionphoto .option-text {
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.optionphoto:hover {
  background: #ddd;
}

/* complementos y ajustes de la cuenta */

.account-things {
  background: #fff;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-right: 0.5rem !important;
}

.account-things h1 {
  padding-top: 1.5rem;
}

.account-things p {
  color: #808080;
  font-weight: 400;
}

/* Para modal de modificacion de foto de portada */

.modal-fotoPortada {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container-mod-portada {
  cursor: pointer;
  width: 100%;
  height: 20rem;
  background-position: "center";
  background-repeat: "no-repeat";
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
}

/* Apartado para los providers en caso no esten linkeados */

.provider-facebook-unliked {
  cursor: pointer;
  color: #3b5998 !important;
}

.provider-email-unliked {
  cursor: pointer;
  color: #3aaf31 !important;
}

.fa-google {
  cursor: pointer !important;
  font-size: 1.5rem !important;
  background: conic-gradient(
      from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg
    )
    73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.error-message-verified {
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-direction: row;
}
.error-message-verified p {
  color: #808080 !important;
  font-size: 1rem;
}

.error-message-verified p span {
  cursor: pointer !important;
  color: #ea4335;
}

.container-form-link{
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media all and (max-width: 968px) {
  .container-Account {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 6rem;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .account-details {
    width: 100%;
    height: 100%;
    margin-top: -0.99rem;
  }

  .header-card-account {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    background-position: "center";
    background-repeat: "no-repeat";
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 12rem;
    position: relative;
  }

  .btn-change-photoP {
    position: absolute;
    top: 100px;
    right: 0px;
    cursor: pointer;
    background: #eee;
    border-radius: 100%;
    padding: 0.2rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  .btn-change-photoPortada {
    position: absolute;
    top: 150px;
    left: 10px;
    cursor: pointer;
    background: #eee;
    border-radius: 100%;
    padding: 0.2rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .account-photo {
    height: 6.5rem;
    width: 6.5rem;
    margin-bottom: -8.5rem;
    pointer-events: none;
    user-select: none;
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .card-account {
    background: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 0rem;
    margin-bottom: 1rem;
    width: 100%;
  }

  .body-card-account h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: #272727;
    font-weight: 600;
    margin-top: 10px;
  }

  .footer-card-account {
    background: #eee;
    margin-top: 0.2rem;
    width: 100%;
    height: 4rem;
    border-bottom-left-radius: 0rem;
    border-bottom-right-radius: 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
  }

  .account-things {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-left: 0;
    margin-right: 0 !important;
    height: 20rem;
    border-radius: 0rem;
  }

  .options-menu-Pprofile {
    position: absolute;
    padding: 0.5rem;
    background: #fff;
    width: 9rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    left: 80px;
    top: 135px;
    z-index: 1;
    display: block;
  }

  .options-menu-Pprofile .optionphoto {
    display: flex;
    height: 35px;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
  }

  .optionphoto .option-text {
    font-size: 12px;
    font-weight: 600;
  }

  .options-menu-Pportada {
    position: absolute;
    padding: 0.5rem;
    background: #fff;
    width: 10rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    left: 12px;
    right: 0 !important;
    top: 188px;
    z-index: 1;
    display: block;
  }

  .options-menu-Pportada .optionphoto {
    display: flex;
    height: 35px;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
  }

  .optionphoto .option-text {
    font-size: 12px;
    font-weight: 600;
  }

  /* Para modal de modificacion de foto de portada */

  .modal-fotoPortada {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .container-mod-portada {
    cursor: pointer;
    width: 100%;
    height: 15rem;
    background-position: "center";
    background-repeat: "no-repeat";
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
  }

  .container-form-link{
    box-shadow: none !important;
    width: 100% !important;
  }
}
