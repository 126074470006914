.container-likeds {
  width: 100%;
  padding-top: 120px;
  height: 100%;
}

.likeds {
  margin-bottom: 50px;
}

.likeds p {
  margin-bottom: 50px !important;
}

.empty-list {
  height: 100vh;
}

.full-list {
  height: 100%;
  width: 100%;
}

.description-likeds {
  text-align: left;
  color: #888888;
  padding-left: 15px;
}

.header-likeds {
  text-align: left !important;
  color: #253d4e;
  padding-left: 15px;
  font-weight: bold;
}

.num-likeds {
  color: white !important;
  background: #009340;
  width: 300px;
  border-radius: 30px;
  padding: 5px;
  font-size: 15px;
  margin-left: 15px;
  text-align: center;
}

.me-liked {
  cursor: pointer !important;
  font-size: 40px;
  color: red;
  outline: none;
  float: right;
}

.producto-liked {
  background-color: white;
  margin-bottom: 30px;
  padding: 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 30px;
  position: relative;
}

.contenido-Liked {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.img-liked {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #009340;
  padding-right: 10px;
  padding-left: 10px;
  width: 350px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.img-liked-img {
  width: 250px !important;
  height: 250px !important;
  padding: 10px;
}

.contenedor-img {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: #fff;
  border-radius: 100%;
  padding: 20px;
}

.info-liked {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
}

.info-liked h2 {
  margin-bottom: 45px;
  font-size: 35px;
  color: #253d4e;
}

.liked-details p {
  text-align: left !important;
  margin-bottom: 30px;
  margin-top: 15px;
  margin-left: 0px !important;
  font-size: 15px !important;
  color: #888888;
}

.liked-details h5 {
  margin-top: -15px;
  color: black;
  font-size: 20px;
}

.badge-liked {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #e20814;
  border-radius: 10px;
  font-size: 40px;
  cursor: pointer;
  outline: none;
}

.btn-liked {
  text-decoration: none;
  background-color: #009340;
  color: white !important;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 8px;
  height: 40px !important;
  border-radius: 30px;
  font-size: 15px !important;
  cursor: pointer;
  margin-right: -15px;
}

.footer-liked {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
}

.empty-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.filters-likeds {
  margin-top: -35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}

.finder-likeds {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  gap: 15px;
}

.filter-change {
  color: #253d4e;
  cursor: pointer;
  font-size: 15px;
}

.container-likeds-small {
  display: grid;
  gap: 5rem 1rem;
  grid-auto-rows: 20rem;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  margin-bottom: 125px !important;
  margin-top: 50px;
}

/* Searchbar */
.box {
  position: relative;
  margin-left: -15px;
  z-index: 1;
}

.input {
  padding: 10px;
  width: 150px;
  height: 40px;
  background: none;
  border: 4px solid #009340;
  border-radius: 50px;
  box-sizing: border-box;
  font-size: 14px;
  color: #fff;
  outline: none;
  transition: 1s;
  display: block;
  background: black;
}
.box:hover input {
  width: 400px;
  background: black;
  border-radius: 10px;
  border: 4px solid black;
}
.box span {
  position: absolute;
  top: 50%;
  right: 83px;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: #fff;
  transition: 0.2s;
}
.box:hover span {
  opacity: 0;
  z-index: -1;
}

.box-min {
  display: none;
}

.result-likeds {
  margin-bottom: -30px;
  color: #253d4e;
  margin-left: 15px;
}

.empty-likeds {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  text-align: center;
  margin-top: 10px;
}

.empty-likeds h1 {
  color: black;
  font-size: 50px;
  font-weight: 800;
  text-transform: uppercase;
}

.empty-likeds p {
  color: #888888;
  font-size: 18px;
  font-weight: 400;
}

.empty-likeds img {
  margin-top: -50px;
  width: 100%;
  height: 100%;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 1));
}
@media all and (max-width: 968px) {
  .container-likeds {
    width: 100%;
    padding-top: 100px;
  }

  .contenido-Liked {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .img-liked {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #009340;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
    border-bottom-left-radius: 0px !important;
  }

  .description-likeds {
    text-align: left;
    font-size: 12px;
    color: #888888;
    padding-left: 0px !important;
  }

  .header-likeds {
    text-align: center !important;
    color: #253d4e;
    padding-left: 0px !important;
    font-weight: bold;
  }

  .num-likeds {
    color: white !important;
    background: #009340;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    font-size: 15px;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  .img-liked-img {
    width: 250px;
    height: auto;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 1));
  }

  .info-liked h2 {
    margin-bottom: 45px;
    font-size: 25px;
    color: #253d4e;
  }

  .liked-details p {
    text-align: left !important;
    margin-bottom: 30px;
    margin-top: 15px;
    margin-left: 0px !important;
    font-size: 14px !important;
    color: #888888;
  }

  .liked-details h5 {
    margin-top: -25px;
    color: black;
    font-size: 20px;
  }

  .contenedor-img {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: white !important;
    border-radius: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
  }

  /* Searchbar */
  .box {
    position: relative;
    margin-left: -15px;
    z-index: 1;
    display: none;
  }

  .box-min {
    display: block;
    position: relative;
    margin-left: 1px;
    z-index: 1;
    width: 25%;
    margin-bottom: 20px;
    z-index: 1;
  }

  .input {
    padding: 10px;
    width: 150px;
    height: 40px;
    background: none;
    border: 4px solid #009340;
    border-radius: 50px;
    box-sizing: border-box;
    font-size: 14px;
    color: #fff;
    outline: none;
    transition: 1s;
    background: black;
  }

  .box-min:hover {
    width: 100% !important;
  }

  .box-min:hover input {
    width: 100% !important;
    background: black;
    border-radius: 10px;
    border: 4px solid black;
  }
  .box-min span {
    position: absolute;
    top: 48%;
    padding-left: 37px;
    transform: translate(-50%, -50%);
    font-size: 30px;
    color: #fff;
    transition: 0.2s;
  }

  .box-min:hover span {
    opacity: 0;
    z-index: -1;
  }

  .empty-likeds h1 {
    color: black;
    font-size: 40px;
    font-weight: 800;
    text-transform: uppercase;
  }

  .empty-likeds p {
    color: #888888;
    font-size: 15px;
    font-weight: 400;
  }
}
