.contenedor-proyecto {
  width: 100%;
  padding-top: 95px;
  background-color: #ddd;
}

.header-project {
  width: 100%;
  height: 550px !important;
  color: white;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
}

.nombre-p {
  color: white;
  font-weight: 600;
}

.contenedor-nombre {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tools-header {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  padding-bottom: 30px;
  padding-top: 120px;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: -100px;
}

.herramientas {
  text-decoration: none;
  color: white;
  background: black;
  width: 120px;
  text-align: center;
  padding: 10px;
  font-weight: 500;
  border-radius: 30px;
  transition: ease-in 0.3s;
  position: relative;
  font-size: 15px;
}

.proyectoi {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: 375px;
  gap: 10px;
  padding-top: 80px !important;
}

.herramientas:hover {
  cursor: pointer;
  color: black;
  background: white;
}

.contenidopDescripcion {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  background: white !important;
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: #1b1b1b;
}

.contenidopDescripcion p {
  width: 100%;
  font-weight: 500;
  color: #808080;
  font-size: 15px;
  line-height: 25px;
  margin-top: 10px;
  letter-spacing: 0.2px;
  text-align: center;
}

.contenidopDescripcion h3 {
  font-weight: bold;
  text-align: center !important;
  color: black;
  font-size: 40px;
}

.tooltip-regresar {
  background: white !important;
  color: #009340 !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-size: 10px !important;
  font-weight: 400 !important;
}

.PropositoP {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 60px;
  margin-bottom: 15px;
  margin-top: 90px !important;
}

.PropositoP h1 {
  margin-bottom: 40px;
  font-size: 40px;
  font-weight: 600;
  color: #009340;
  padding-top: 35px;
}

.p-b {
  font-size: 20px;
  font-weight: 400;
  border-radius: 50px;
  margin-bottom: 20px;
  margin-top: -30px;
}

.GaleriaP {
  background-color: #163288;
  color: white;
  height: 550px !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 55px;
  padding-bottom: 50px !important;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  margin-top: -75px;
  padding-left: 50px;
}

.tituloG {
  font-size: 50px;
  margin-bottom: 25px;
  padding-top: -10px;
  line-height: 60px !important;
  color: white;
}

.pGaleria {
  margin-bottom: 30px;
  color: #ddd;
  font-size: 15px;
  font-weight: 400;
}

.slid img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.mySwiper {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 40px;
}

.slid {
  background-position: center;
  background-size: cover;
  width: 450px;
  height: 450px;
}

.productsProject h1 {
  font-size: 40px;
  font-weight: 600;
  color: #009340;
}

.encabezado-galeria {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}

@media all and (max-width: 968px) {
  .tools-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
    padding-top: 100px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .proyectoi {
    grid-template-columns: 100%;
    margin: 0 !important;
    padding-top: 80px !important;
    grid-auto-rows: 250px;
    gap: 15px;
  }

  .contenidopNombre {
    margin-top: 0px !important;
    margin-bottom: 15px !important;
    width: 100% !important;
  }

  .contenidopDescripcion {
    width: 100% !important;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }

  .contenidopDescripcion h3 {
    font-size: 25px;
    font-weight: 700;
  }

  .contenidopDescripcion p {
    width: 100%;
    font-weight: 400;
    color: #808080;
    font-size: 15px;
    line-height: 20px;
  }

  .PropositoP {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -145px !important;
    padding-bottom: 60px !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .PropositoP h1 {
    margin-bottom: 15px;
    font-size: 30px;
    font-weight: 700;
    color: #28a745;
    margin-top: 390px;
  }

  .p-b {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin-top: -5px !important;
  }

  .GaleriaP {
    background-color: #010c58;
    color: white;
    height: 100% !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px;
  }

  .tituloG {
    font-size: 25px !important;
    margin-bottom: 15px;
    line-height: 30px !important;
    text-align: center !important;
  }

  .pGaleria {
    margin-bottom: 10px;
    font-size: 12px !important;
    color: #ddd;
    text-align: center;
  }
  .encabezado-galeria {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    padding-top: 20px;
    text-align: center !important;
  }
}
