/*First section*/
html {
  scroll-behavior: smooth;
}

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;500&display=swap");

@font-face {
  font-family: "LuzColor";
  src: local("LuzColor"),
    url(../../../public/fonts/BillionDreams_PERSONAL.ttf) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

body {
  background: #ddd;
  font-family: "nunito", sans-serif !important;
  font-family: var(--font-family);
}




.contenedor-index {
  background: #fff !important;
}

.First {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding-top: 150px;
  line-height: 30px;
}
.Protecto {
  color: #163288 !important;
}

.El-Salvador {
  color: #0045a6 !important;
}
.LuzColor {
  color: #28a745 !important;
  font-family: LuzColor !important;
}
.principal {
  width: 100%;
  padding: 30px;
}
.principal h1 {
  font-size: 60px;
  line-height: 65px;
  font-weight: bold;
  letter-spacing: 0.3px;
  padding-bottom: 10px;
}

.img-principal img {
  width: 100% !important;
  height: auto;
}

.img-principal {
  display: grid;
  place-items: center;
  padding-left: 30px;
}

.bienvenida {
  font-weight: bold;
  color: #28a745;
  font-size: 22px;
  margin-top: -80px;
}
.descripcion {
  font-size: 18px;
  font-weight: 400;
  color: black;
  text-align: left;
}
.comenzar-btn {
  border-radius: 60px !important;
  color: white !important;
  padding-left: 15px;
  padding-right: 15px;
  transition: ease-in-out 0.5s;
  font-size: 15px;
}
.comenzar-btn:hover {
  color: #28a745 !important;
  background: white !important;
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.red-btn{
  border-radius: 60px !important;
  color: white !important;
  padding-left: 15px;
  padding-right: 15px;
  transition: ease-in-out 0.5s;
  font-size: 15px;
}

.red-btn:hover {
  color: #BB2D3B !important;
  background: white !important;
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
/*End first section*/
/*Second Section */
.Second {
  height: 100vh;
  background: #28a745;
  width: 100%;
  color: white !important;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 50px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.Second h1 {
  font-weight: bolder;
  text-align: center;
  padding-top: 50px;
  margin-bottom: 50px;
}

.Second a {
  text-decoration: none;
}

.Second p {
  font-size: 15px;
  font-weight: bold;
  background: white !important;
  color: #28a745;
  padding: 10px 30px 10px 30px;
  border-radius: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease-in-out 0.2s;
  width: 450px;
}

.Second p:hover {
  color: #ddd !important;
  background: #1b1b1b !important;
}
/* Third section */
.Third {
  height: 100vh;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  background: #fff;
  padding-top: 40px;
  padding-left: 80px;
}

.headerThird {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 100px;
}

.headerThird h1 {
  font-size: 60px;
  line-height: 75px;
  font-weight: bold;
  letter-spacing: 0.3px;
  padding-bottom: 10px;
  color: #28a745;
}

.prom {
  font-size: 18px;
  font-weight: 400;
  color: #808080;
  text-align: left;
}

.proyecto {
  font-size: 15px;
  font-weight: 600;
  color: #163288;
  margin-top: -30px;
}

.headerThird a {
  text-decoration: none;
}

.headerThird a button {
  display: flex !important;
  justify-content: center !important;
  align-items: flex-end !important;
}

/* Cuarta seccion */
.Fourth {
  height: 100vh;
  background: #1b1b1b;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  justify-content: center;
}

.Fourth .nosotros {
  color: #fff;
  padding: 30px;
}

.nosotros-img {
  background: white;
  padding-left: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.nosotros h1 {
  font-size: 60px;
  font-weight: bold;
  color: #28a745;
  margin-top: 20px;
  margin-bottom: 20px;
}

.count-you {
  font-size: 15px;
  font-weight: 400;
  color: #808080;
}

.Fourth .nosotros .principal-p {
  text-align: left;
  margin-top: 15px;
  font-size: 15px;
  color: white;
  line-height: 25px;
  margin-bottom: 30px;
}

.img-reference {
  width: 100%;
  height: auto;
  border-top-left-radius: 70px;
  border-bottom-left-radius: 70px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.img-us {
  background: #163288;
  border-top-left-radius: 70px;
  border-bottom-left-radius: 70px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.Fifth {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  align-items: center;
  justify-content: center;
  background: #fff;
  width: 100%;
  height: 100vh;
  padding-right: 20px;
  padding-top: 50px;
}

.Fifth h1 {
  color: #1b1b1b;
  font-weight: bold;
  font-size: 60px;
  margin-bottom: 15px;
}

.services-ours {
  width: 100%;
  padding: 30px;
}
.in {
  font-size: 18px;
  font-weight: 400;
  color: #808080;
  text-align: left;
  line-height: 25px;
}

.services-luz {
  color: #28a745;
  font-size: 22px;
  font-family: LuzColor;
  font-weight: 500;
}

.services {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  transition: all ease-in 0.3s;
}

.services ol li {
  list-style-type: none;
  counter-increment: item;
  margin-bottom: 15px;
  font-size: 15px;
  color: black;
  font-weight: 500;
}

.services ol li:before {
  content: counter(item);
  margin-right: 10px;
  font-size: 12px;
  background-color: #28a745;
  color: white;
  font-weight: bold;
  padding: 3px 8px;
  border-radius: 100%;
}

/* Final part */

.Final {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #163288;
  color: white;
  flex-direction: column;
  padding-top: 100px;
}

.Final h1 {
  font-size: 60px;
  font-weight: bold;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.Final p {
  font-size: 15px;
  font-weight: 500;
  color: #ddd;
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 25px;
}

.contact-btn {
  background: white;
  color: #28a745 !important;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 40px;
  font-size: 20px !important;
  font-weight: bolder;
  margin-top: 25px;
  transition: all ease-in 0.3s;
  -webkit-animation: heartbeat 2.5s ease-in-out infinite both;
  animation: heartbeat 2.5s ease-in-out infinite both;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.contact-btn:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  color: white !important;
  background: #28a745;
}

.LuzColor-2 {
  color: white !important;
  font-family: LuzColor;
  font-size: 35px;
  font-weight: 400;
  border: 5px solid;
  padding: 10px;
  border-image: linear-gradient(
      to right,
      #e20814 25%,
      #e96b06 25%,
      #ffd63b 50%,
      #bdd476 50%,
      #009340 75%,
      #28a745 75%
    )
    5;
}

/* Clases para el preloader */

.agrupador {
  position: fixed;
  inset: 0;
  z-index: 99999;
  background-color: #1b1b1b;
  display: grid;
  place-items: center;
  transition: opacity 1s, visibility 1s;
}

.fade {
  opacity: 0;
  visibility: hidden;
}

/* Spinner preload */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #ddd; /* Light grey */
  border-top: 10px solid #28a745; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

/*Media querys*/

@media all and (max-width: 968px) {
  .First {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
    padding-top: 80px !important;
    padding-left: 0px;
  }

  .principal {
    width: 100%;
  }

  .bienvenida {
    font-weight: bold;
    color: #28a745;
    font-size: 25px !important;
    margin-top: 0px;
  }

  .principal h1 {
    font-size: 40px;
    line-height: 50px !important;
    font-weight: bolder;
  }

  .descripcion {
    font-size: 15px !important;
    font-weight: 400;
    color: #1b1b1b;
    line-height: 22px;
    margin-bottom: -15px;
  }
  .img-principal {
    margin-bottom: 30px;
  }
  .comenzar-btn {
    border-radius: 60px !important;
    color: white !important;
    padding-left: 15px;
    padding-right: 15px;
    transition: ease-in-out 0.2s;
    font-size: 12px;
    display: none !important;
  }

  .red-btn{
    border-radius: 60px !important;
    color: white !important;
    padding-left: 15px;
    padding-right: 15px;
    transition: ease-in-out 0.5s;
    font-size: 12px;
  }

  .Second {
    height: 100%;
    background: #28a745;
    width: 100%;
    color: white !important;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    padding-top: 20px !important;
    padding-bottom: 50px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }

  .Second h1 {
    font-weight: bolder;
    padding-top: 50px;
    margin-bottom: 50px;
    font-size: 25px !important;
    min-width: 350px !important;
  }

  .Second p {
    font-size: 10px !important;
    font-weight: 600;
    background: white !important;
    color: #28a745;
    max-width: 300px !important;
    border-radius: 50px;
    transition: ease-in-out 0.2s;
  }

  .Third {
    display: flex;
    width: 100%;
    flex-direction: column !important;
    height: 100%;
    padding-top: 50px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 5px !important;
  }
  .headerThird {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 0;
  }
  .headerThird h1 {
    font-size: 40px;
    line-height: 40px;
    font-weight: bold;
    letter-spacing: 0.3px;
    padding-bottom: 10px;
    color: #28a745;
  }

  .headerThird a {
    margin-left: -300px;
  }

  .prom {
    font-size: 15px !important;
    font-weight: 400;
    color: #1b1b1b;
    text-align: left;
  }

  .proyecto {
    font-size: 15px !important;
    font-weight: 600;
    color: #163288;
    margin-top: -30px;
  }

  .Fourth {
    height: 100%;
    background: #1b1b1b;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }

  .Fourth .nosotros {
    color: #ddd;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
  }

  .nosotros h1 {
    font-size: 40px;
    font-weight: bold;
    color: #28a745;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .img-us {
    background: white;
    margin-left: 30px;
    display: none;
  }

  .principal-p {
    font-size: 15px !important;
  }

  .Fifth {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #fff;
    width: 100%;
    height: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0;
  }

  .Fifth h1 {
    color: #1b1b1b;
    font-weight: bolder;
    font-size: 40px;
    margin-bottom: 15px;
    margin-left: 0px;
  }

  .services-ours {
    width: 100%;
  }
  .in {
    font-size: 15px !important;
    font-weight: 400;
    color: #1b1b1b;
    text-align: left;
    line-height: 25px;
  }

  .services-luz {
    color: #28a745;
    font-size: 25px;
    font-family: LuzColor;
  }

  .services {
    margin-top: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 20px;
    background: white;
    margin-bottom: 15px;
  }

  .services ol li {
    list-style-type: none;
    counter-increment: item;
    margin-bottom: 15px;
    font-size: 15px !important;
    color: #1b1b1b;
    font-weight: 400;
  }

  .services ol li:before {
    content: counter(item);
    margin-right: 10px;
    font-size: 12px;
    background-color: #28a745;
    color: #ddd;
    font-weight: bold;
    padding: 3px 8px;
    border-radius: 100%;
  }

  .Final {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #163288;
    color: white;
    flex-direction: column;
    padding-top: 50px;
    padding-right: 10px;
    padding-bottom: 20px;
  }

  .Final h1 {
    font-size: 40px;
    font-weight: bold;
    color: white;
    text-align: left;
  }

  .Final p {
    font-size: 15px;
    font-weight: 400;
    color: #ddd;
    padding: 0;
    text-align: left;
  }

  .LuzColor-2 {
    color: #fff !important;
    font-family: LuzColor;
    font-size: 25px;
    font-weight: 400;
    border: 5px solid;
    padding: 10px;
    border-image: linear-gradient(
        to right,
        #e20814 25%,
        #e96b06 25%,
        #ffd63b 50%,
        #bdd476 50%,
        #009340 75%,
        #28a745 75%
      )
      5;
    margin-top: 10px;
  }
  .contact-btn {
    background: white;
    color: #009340 !important;
    padding-top: 8px !important;
    padding-left: 35px !important;
    padding-right: 35px !important;
    padding-bottom: 8px !important;
    border-radius: 40px;
    font-size: 18px !important;
    font-weight: bolder;
    margin-top: 25px;
    transition: all ease-in 0.3s;
    margin-bottom: 15px;
  }
}

@media all and (max-width: 1024px) {
  .First {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 100%;
    padding-top: 120px;
    padding-left: 0px;
  }

  .principal {
    width: 100%;
  }

  .bienvenida {
    font-weight: bold;
    color: #28a745;
    font-size: 20px;
    margin-top: 0px;
  }

  .principal h1 {
    font-size: 45px;
    line-height: 75px;
    font-weight: bolder;
  }

  .descripcion {
    font-size: 20px;
    font-weight: 400;
    color: #1b1b1b;
  }

  .Second {
    height: 100%;
    background: #28a745;
    width: 100%;
    color: white !important;
    padding-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 100px;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 50px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }

  .Third {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    height: 100%;
    padding-top: 50px;
    padding-left: 0px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
  }

  .img-us {
    background: white;
    margin-left: 30px;
    display: none;
  }

  .Fourth {
    height: 100%;
    background: #1b1b1b;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }

  .Fourth .nosotros {
    color: #ddd;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
  }

  .nosotros h1 {
    font-size: 40px;
    font-weight: bold;
    color: #28a745;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .img-us {
    background: white;
    margin-left: 30px;
    display: none;
  }
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
