:root {
    --surface-color: #fff;
    --curve: 40;
  }

.card {
    position: relative;
    display: block;
    height: 100%;  
    border-radius: calc(var(--curve) * 1px);
    overflow: hidden;
    text-decoration: none;
    transition: ease-in all .5s;
    outline: none;
  }
  
  .card__image {      
    width: 100%;
    height: auto;
  }
  
  .card__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;      
    border-radius: calc(var(--curve) * 1px);    
    background-color: var(--surface-color);      
    transform: translateY(100%);
    transition: .2s ease-in-out;
  }
  
  .card:hover .card__overlay {
    transform: translateY(0);
  }
  
  .card__header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2em;
    padding: 2em;
    border-radius: calc(var(--curve) * 1px) 0 0 0;    
    background-color: var(--surface-color);
    transform: translateY(-100%);
    transition: .2s ease-in-out;
  }
  
  .card__arc {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 100%;
    right: 0;      
    z-index: 1;
  }
  
  .card__arc path {
    fill: var(--surface-color);
    d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
  }       
  
  .card:hover .card__header {
    transform: translateY(0);
  }
  
  .card__thumb {
    flex-shrink: 0;   
    border-radius: 50%;  
    color:  #163288 !important;    
  }
  
  .card__title {
    font-size: 15px;
    margin: 0 0 .3em;
    color: #6A515E;
    font-weight: 600;
  }
  
  .card__tagline {
    display: block;
    margin: 1em 0;
    font-family: "MockFlowFont";  
    font-size: .8em; 
    color: #D7BDCA;  
  }
  
  .card__status {
    font-size: .8em;
    color: #808080;
    font-weight: 600;
  }
  
  .card__description {
    padding: 0 2em 2em;
    margin: 0;
    color: #808080 !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }    

  .card:hover{
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }