.container-categories {
  width: 100%;
  height: 100%;
  padding-top: 150px;
}

.barra-Busqueda {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px !important;
  padding: 10px;
  margin-top: 25px;
  margin-bottom: 55px;
  font-size: 15px;
  overflow: hidden;
  font-weight: 600;
  text-align: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.barra-Busqueda:focus {
  border: 2px solid #28a745 !important;
  outline: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.iconoBusqueda {
  margin-top: -30px;
  font-size: 45px;
  color: #28a745 !important;
  margin-right: -49.5px;
  position: relative;
}

.header-cats {
  font-size: 40px;
  font-weight: 600;
  color: #28a745;
  margin-bottom: 15px;
}

.number {
  font-size: 18px;
  font-weight: 400;
  color: white;
  background: #1b1b1b;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 455px;
  margin-top: 10px;
}

.Empty {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Empty p {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.Finder {
  padding-top: 25px;
  font-size: 18px;
  font-weight: bolder;
  margin-bottom: -15px;
}

.categories-others {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.head-cat {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

/* Aside categories */

.categories-aside {
  list-style: none;
  margin-top: 10px;
  margin-left: 0px;
  margin-right: 30px;
  width: 100%;
}

.categories-aside li {
  margin-bottom: 20px;
  background: white;
  border-radius: 50px;
  text-align: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: ease 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}

.categories-aside a {
  text-decoration: none;
}

.categories-aside li:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.categories-aside li p {
  text-decoration: none !important;
  color: #1b1b1b;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 400;
}

.others {
  font-size: 15px;
  font-weight: 400;
  margin-right: 0px;
  color: gray;
  text-align: center;
}

.Active {
  background: #28a745 !important;
}

.Active p {
  color: white !important;
  font-weight: 600;
}

.Galeria-md {
  display: none !important;
}

.cat-md {
  display: none !important;
}

.contenedor-lista {
  display: grid;
  grid-auto-rows: 20rem;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  gap: 12px 12px;
  width: 100%;
  margin-bottom: 15px;
}

@media all and (max-width: 968px) {
  .header-cats {
    font-size: 25px;
    font-weight: 600;
    color: #28a745;
    margin-bottom: 15px;
  }
  .barra-Busqueda {
    width: 100%;
    margin-top: 35px !important;
  }

  .categories-others {
    display: none !important;
  }
  .Finder {
    padding-top: 25px !important;
    font-size: 15px;
    font-weight: bolder;
    margin-bottom: 0;
    position: absolute;
  }

  .number {
    font-size: 15px;
    font-weight: 400;
    color: white;
    background: #1b1b1b;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }

  .Galeria-md {
    display: block !important;
    background: none;
  }

  .Galeria-md li {
    background: white;
    border-radius: 30px;
    text-decoration: none;
    color: #808088;
    text-align: center;
    cursor: pointer;
  }

  .Galeria-md li p {
    padding: 5px;
    font-size: 10px;
    font-weight: 600;
    color: #1b1b1b;
  }

  .cat-md {
    display: block !important;
    margin-top: -50px;
    color: #808088;
    font-weight: 400;
  }

  .others {
    display: none !important;
  }

  .iconoBusqueda {
    margin-top: -20px;
    font-size: 45px;
    color: #28a745 !important;
    margin-right: -49.5px;
    position: relative;
  }

  .contenedor-lista {
    display: grid;
    grid-auto-rows: 20rem;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    gap: 12px 12px;
    width: 100%;
    margin-bottom: 15px;
  }
}
