.link-card-product {
  text-decoration: none !important;
  color: #1b1b1b !important;
  cursor: pointer;
}
.card-product {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: ease-in 0.4s;
  padding-top: -50px;
  margin-bottom: 50px;
  padding-bottom: 5px;
  border-radius: 15px !important;
  border-bottom: 10px solid #009340;
  max-height: 350px;
}

.img-cardp {
  margin-top: -50px;
  height: 250px !important;
  width: 250px !important; 
}

.card-product p {
  font-weight: 600;
  font-size: 14px;
  color: black;
  line-height: 10px;
}

.vermas {
  text-decoration: none;
  background-color: #009340;
  color: white !important;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 11px;
  min-height: 35px !important;
  border-radius: 30px;
  font-size: 12px !important;
  font-weight: 500 !important;
}


.badge {
  position: absolute;
  left: 5px;
  top: 15px;
  color: #fff;
  background: #28a745;
  border-radius: 10px;
  font-size: 12px;
  padding: 3px 10px;
  text-transform: uppercase;
}

.badge {
  position: absolute;
  left: 5px;
  top: 15px;
  color: #fff;
  background: #28a745;
  border-radius: 10px;
  font-size: 12px;
  padding: 3px 10px;
  text-transform: uppercase;
}

.badge-favorite {
  position: absolute;
  right: 1px;
  top: 1px;
  color: #e20814;
  border-radius: 10px;
  font-size: 40px;
  cursor: pointer;
  outline: none;
}

.price-product {
  font-size: 12px;
  color: #808080 !important;
  font-weight: 600;
  display: none;
}
.card-product:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

@media all and (max-width: 968px) {
  .vermas {
    text-decoration: none;
    background-color: #009340;
    color: white !important;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 12px;
    min-height: 35px !important;
    border-radius: 30px;
    font-size: 12px !important;
  }
}
