.imgAd {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center;
}

.slide-ad {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

.Galeria {
  width: 70%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
}


@media all and (max-width: 968px) {


  .Galeria {
    width: 60% !important;
    height: auto;
  }
}

@media all and (max-width: 1024px) {
  .Galeria {
    width: 50%;
    height: auto;
  }
}