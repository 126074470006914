.contenedor-login {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  text-align: center;
  position: absolute;
  inset: 0;
  z-index: 3;
  background: #fff;
}

.btn-facebook {
  background: #3b5998;
  color: #fff;
  padding: 12px;
}

.btn-facebook:hover {
  color: #fff;
  opacity: 0.8;
}

.login-contenedor {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: -8rem;
}

.login {
  border-radius: 10%;
  width: 30rem;
}

.login h1 {
  font-weight: 400;
  color: #7875b5;
  margin-bottom: 30px;
}

.header-form-login {
  width: 100%;
  border-radius: 0.8rem;
  padding: 1rem;
  margin-bottom: -50px;
}

.form-login {
  padding-left: 1rem;
  padding-right: 1rem;
}

.login-others-zone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: -1rem;
  gap: 15px;
}

.aside-left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../public/fondo-login.jpg");
  background-position: "center";
  background-repeat: "no-repeat";
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  height: 100%;
  width: 100%;
  color: #fff;
}

.aside-left h1 {
  font-family: LuzColor !important;
  font-size: 8rem;
  letter-spacing: 5px;
}

.aside-left p {
  color: #ddd;
  font-size: 15px;
  margin-top: -10px;
}

.nav-login {
  display: none;
}

.btn-regresar {
  background: #3b5998;
}

.btn-logins {
  border-radius: 60px !important;
  color: white !important;
  padding-left: 15px;
  padding-right: 15px;
  transition: ease-in-out 0.2s;
  font-size: 15px;
}

.btn-logins:hover {
  color: #28a745 !important;
  background: white !important;
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.login-formulario {
  width: 100%;
  margin-bottom: 20px;
}

.login__field {
  padding: 20px 0px;
  position: relative;
}

.login__icon {
  position: absolute;
  top: 30px;
  left: 1px;
  color: #7875b5;
}

.login__icon-password {
  position: absolute;
  top: 30px;
  right: 1px;
  color: #7875b5;
  cursor: pointer;
}

.login__input {
  display: block;
  border: none;
  border-bottom: 2px solid #d1d1d4;
  background: none;
  padding: 10px;
  padding-left: 24px;
  font-weight: 400;
  width: 100%;
  transition: 0.2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
  outline: none !important;
  border-bottom-color: #6a679e !important;
}

.login__submit {
  background: #fff;
  font-size: 14px;
  margin-top: 8px;
  padding: 16px 20px;
  border-radius: 26px;
  border: 1px solid #d4d3e8;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #4c489d;
  box-shadow: 0px 2px 2px #5c5696;
  cursor: pointer;
  transition: 0.2s;
  margin-bottom: 8px;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
  border-color: #6a679e;
  outline: none;
}

.button__icon {
  font-size: 24px;
  margin-left: auto;
  color: #7875b5;
}

.continue-message {
  color: #808080;
  margin-top: -10px;
  font-size: 12px;
}

.terms {
  font-size: 10px;
  color: #808080;
  font-weight: 200;
}

.already {
  margin-top: 10px;
  font-size: 15px;
  margin-bottom: -15px;
  color: #808080;
}

.already span {
  color: #3b5998;
  cursor: pointer;
}

/* Apartado del reset de password */

.aside-left-reset {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../public/fondo-reset.jpg");
  background-position: "center";
  background-repeat: "no-repeat";
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  height: 100%;
  width: 100%;
  color: #fff;
}
.aside-left-reset h1 {
  font-family: LuzColor !important;
  font-size: 8rem;
  letter-spacing: 5px;
}

.aside-left-reset p {
  color: #ddd;
  font-size: 15px;
  margin-top: -10px;
}

.contenedor-reset {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  text-align: center;
  position: absolute;
  inset: 0;
  z-index: 3;
  background: #fff;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.input-container h1 {
  font-weight: 400;
  color: #7875b5;
  font-size: 4rem;
}

.message-reset {
  color: #808080;
  font-size: 15px;
}

.btn-send-reset {
  background: #fff;
  font-size: 14px;
  margin-top: 8px;
  padding: 16px 20px;
  border-radius: 26px;
  border: 1px solid #d4d3e8;
  text-transform: uppercase;
  font-weight: 700;
  color: #4c489d;
  box-shadow: 0px 2px 2px #5c5696;
  cursor: pointer;
  transition: 0.2s;
  margin-bottom: 25px;
}

.btn-send-reset:hover {
  border-color: #6a679e;
  outline: none;
}

.login__input__reset {
  display: block;
  border: none;
  border-bottom: 2px solid #d1d1d4;
  background: none;
  padding: 10px;
  padding-left: 24px;
  font-weight: 400;
  width: 100%;
  transition: 0.2s;
}

.login__input__reset:active,
.login__input__reset:focus,
.login__input__reset:hover {
  outline: none !important;
  border-bottom-color: #6a679e !important;
}

@media all and (max-width: 968px) {
  .contenedor-login {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: none !important;
    align-items: none !important;
    flex-direction: column;
    text-align: center;
    display: grid;
    grid-template-columns: 100%;
    position: absolute;
  }

  .header-form-login h1 {
    color: #4c489d !important;
    font-weight: 600;
    font-size: 2.1rem;
    margin-bottom: 100px;
  }

  .nav-login {
    display: block;
  }

  .login-contenedor {
    box-shadow: none;
    background: #4c489d !important;
  }

  .aside-left {
    display: none;
  }

  .aside-left-reset {
    display: none;
  }

  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 0;
    width: 100%;
    background: #fff;
    padding-top: 30px;
    height: 100%;
    padding-bottom: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
  }

  .continue-message {
    color: #ddd !important;
    margin-top: -15px;
    font-size: 0.8rem;
  }

  .already {
    margin-top: 10px;
    font-size: 0.8rem !important;
    margin-bottom: -15px;
    color: #808080;
  }

  .login-others-zone {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0rem;
    gap: 2px !important;
  }

  .form-login {
    margin-top: -60px;
  }

  .terms {
    color: #ddd !important;
  }

  .login__input__reset {
    display: block;
    border: none;
    border-bottom: 2px solid #d1d1d4;
    background: none;
    padding: 10px;
    padding-left: 24px;
    font-weight: 400;
    width: 100%;
    transition: 0.2s;
    color: #fff !important;
  }

  .login__input__reset:active,
  .login__input__reset:focus,
  .login__input__reset:hover {
    outline: none !important;
    border-bottom-color: #fff !important;
  }

  .btn-send-reset {
    background: #fff;
    font-size: 12px;
    margin-top: 8px;
    padding: 16px 20px;
    border-radius: 26px;
    border: 1px solid #d4d3e8;
    text-transform: uppercase;
    font-weight: 700;
    color: #4c489d;
    box-shadow: 0px 2px 2px #5c5696;
    cursor: pointer;
    transition: 0.2s;
    margin-bottom: 25px;
  }

  .contenedor-reset {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    position: fixed;
    inset: 0;
    z-index: 3;
    background: #fff;
    width: 100%;
    height: 100%;
  }
  .input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    background: #4c489d !important;
  }

  .input-container h1 {
    font-weight: 400;
    font-size: 2rem;
    color: #fff !important;
  }
  .message-reset {
    color: #ddd !important;
    font-size: 12px;
    margin-bottom: 4rem;
    text-align: center;
  }
}
