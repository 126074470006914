* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "LuzColor";
  src: local("LuzColor"),
    url(../../../public/fonts/BillionDreams_PERSONAL.ttf) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.likeds-icon {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 18px;
  right: 7px;
  color: white;
  font-size: 10px !important;
  padding: 8px !important;
  padding: 5px;
  height: 10px !important;
  width: 10px;
  border-radius: 100%;
  background-color: #28a745;
}

nav {
  color: white !important;
  padding-top: 10px;
  background: #1b1b1b;
  position: fixed;
  width: 100%;
  z-index: 2;
}

nav:after {
  content: "";
  clear: both;
  display: table;
}

nav .logo {
  float: left;
  color: #28a745 !important;
  font-size: 27px;
  line-height: 70px;
  padding-left: 60px;
  font-family: LuzColor !important;
  display: flex;
  align-items: center;
}

nav .logo span {
  font-family: LuzColor !important;
}

nav .logo a {
  color: #28a745 !important;
  text-decoration: none !important;
}

nav ul {
  float: right;
  list-style: none;
  margin-right: 40px;
  position: relative;
}

nav ul li {
  display: inline-block;
  background: #1b1b1b;
  margin: 0 5px;
  float: left;
}

.LinkMenu {
  color: white;
  text-decoration: none;
  line-height: 30px;
  font-size: 18px;
  padding: 8px 15px;
}

.LinkMenu.active {
  color: #28a745 !important;
  transition: all ease-in-out 0.2s;
}

nav ul li a:hover,
nav ul li a.active {
  color: white !important;
  border-bottom: 2px solid #28a745;
}

nav ul ul li a:hover {
  color: #28a745;
  box-shadow: none;
}

.LinkMenu:hover {
  color: #28a745;
  box-shadow: none;
  transition: all ease-in-out 0.2s;
}

nav ul ul {
  position: absolute;
  top: 90px;
  border-top: 3px solid #28a745;
  opacity: 0;
  visibility: hidden;
  transition: top 0.3s;
}

nav ul ul ul {
  border-top: none;
}

nav ul li:hover > ul {
  top: 70px;
  opacity: 1;
  visibility: visible;
}

nav ul ul li {
  position: relative;
  margin-left: -30px;
  width: 180px;
  float: none;
  display: list-item;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

nav ul ul li a {
  line-height: 50px;
  text-align: center;
}

.LinkMenu {
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}

nav ul ul ul li {
  position: relative;
  top: -70px;
  left: 150px;
}

.show,
input,
.icon {
  display: none;
}

.likeditembutton {
  position: relative;
  border-radius: 2px;
  z-index: 0;
}

/* Apartado de otro menu a un costado*/

.wrapper-aside {
  position: fixed;
  top: 0;
  /*left: -100%;*/
  right: -100%;
  height: 100%;
  width: 100%;
  background: #000;
  /*background: linear-gradient(90deg, #f92c78, #4114a1);*/
  /* background: linear-gradient(375deg, #1cc7d0, #2ede98); */
  /* background: linear-gradient(-45deg, #e3eefe 0%, #efddfb 100%);*/
  transition: all 0.6s ease-in-out;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}
#active:checked ~ .wrapper-aside {
  /*left: 0;*/
  right: 0;
}
.menu-btn {
  position: relative;
  z-index: 2;
  right: 0;
  /*left: 20px; */
  top: 12px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  font-size: 10px;
  cursor: pointer;
  color: black;
  padding: 0;
  background: white;
  transition: all 0.3s ease-in-out;
}
.menu-btn span,
.menu-btn:before,
.menu-btn:after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  left: 30%;
  width: 40%;
  border-bottom: 2px solid black;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  color: black !important;
}
.menu-btn:before {
  transform: translateY(-8px);
}
.menu-btn:after {
  transform: translateY(8px);
}

.logo-aside {
  font-family: LuzColor;
  color: #fff !important;
  font-size: 35px !important;
  line-height: 35px;
  margin-bottom: 45px;
  border-bottom: 2px solid #28a745;
}

.close {
  z-index: 1;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: background 0.6s;
  color: black;
}

.items-aside {
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 15px;
}

.items-aside-active {
  cursor: pointer;
  text-decoration: none;
  border-bottom: 2px solid #28a745;
  margin-bottom: 15px;
}

/* closing animation */
#active:checked + .menu-btn span {
  transform: scaleX(0);
}
#active:checked + .menu-btn:before {
  transform: rotate(45deg);
  border-color: black;
}
#active:checked + .menu-btn:after {
  transform: rotate(-45deg);
  border-color: black;
}

.wrapper-aside h1 {
  font-size: 18px;
  color: #fff;
  font-weight: 400;
}

.items-aside:hover {
  color: #28a745;
}

.wrapper-aside a {
  opacity: 0;
  transition: all 0.3s ease;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.wrapper-aside p {
  color: #ddd;
  font-weight: 400;
}

input[type="checkbox"] {
  display: none;
}

#active:checked ~ .wrapper-aside a {
  opacity: 1;
}
.wrapper-aside a {
  transition: opacity 1.2s, transform 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateX(100px);
}
#active:checked ~ .wrapper-aside a {
  transform: none;
  transition-timing-function: ease, cubic-bezier(0.1, 1.3, 0.3, 1); /* easeOutBackを緩めた感じ */
  transition-delay: 0.6s;
}

.user-info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row-reverse;
  font-size: 14px;
  padding: 20px;
  gap: 10px;
}

.user-info p {
  color: #fff;
}

.user-info img {
  clip-path: circle();
  width: 5rem;
  height: 5rem;
  margin-right: 0.1rem;
  pointer-events: none;
  user-select: none;
}

.account {
  line-height: 15px;
}

.verified {
  margin-top: -5px;
  color: #28a745 !important;
  font-size: 0.8rem;
}

.verified span {
  cursor: pointer;
  color: #fff !important;
  border-bottom: 1px solid #fff;
}

.section-avatar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-direction: row;
  margin-top: 1rem;
  gap: 0.8rem;
}

.name-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1px;
}

.section-avatar p {
  font-size: 0.8rem;
  font-weight: 400;
}

.section-avatar span {
  font-size: 0.6rem;
  color: #808080;
}

.avatar-user {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-left: -0.2rem;
  margin-top: -1rem;
  cursor: pointer;
  color: #ffff !important;
}

.avatar-user span {
  color: #fff !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  text-align: center;
}

.avatar-user-small {
  display: none !important;
}

.sign-out-smal {
  display: none;
}

.activeAccount {
  border: 2px solid #fff;
}

@media all and (max-width: 968px) {
  nav ul {
    margin-right: 0px;
    float: left;
  }
  nav .logo {
    padding-left: 30px;
    width: 100%;
  }
  nav ul li,
  nav ul ul li {
    display: block;
    width: 100%;
  }

  nav ul ul {
    top: 70px;
    position: static;
    border-top: none;
    float: none;
    display: none;
    opacity: 1;
    visibility: visible;
  }
  nav ul ul ul {
    position: static;
  }

  nav ul ul li {
    border-bottom: 0px;
  }

  nav ul ul a {
    padding-left: 40px;
  }

  nav ul ul ul a {
    padding-left: 80px;
  }

  .show {
    display: block;
    color: white;
    font-size: 18px;
    padding: 0 20px;
    line-height: 70px;
    cursor: pointer;
  }

  .show:hover {
    color: #28a745;
  }

  .icon {
    display: block;
    position: absolute;
    color: white;
    right: 40px;
    line-height: 70px;
    font-size: 25px;
    cursor: pointer;
    margin-top: -5px;
  }

  nav ul li a:hover {
    box-shadow: none;
  }
  .show + a,
  ul {
    display: none;
  }
  [id^="btn"]:checked + ul {
    display: block;
  }

  .menu-btn {
    position: relative;
    z-index: 2;
    right: 0;
    /*left: 20px; */
    top: 1px;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    /*color: #fff;*/
    /*background: linear-gradient(90deg, #f92c78, #4114a1);*/
    /* background: linear-gradient(375deg, #1cc7d0, #2ede98); */
    /* background: linear-gradient(-45deg, #e3eefe 0%, #efddfb 100%); */
    transition: all 0.3s ease-in-out;
    color: white !important;
  }

  .section-avatar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    flex-direction: row;
    margin-top: 1rem;
    gap: 0.1rem;
    display: none;
  }

  .avatar-user {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 0rem;
    margin-left: 1rem;
    cursor: pointer;
  }

  .avatar-user-small {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: -0.5rem;
    margin-left: 1.5rem;
    cursor: pointer;
  }

  .avatar-user-small span {
    color: #fff !important;
    font-size: 1rem !important;
    font-weight: bold !important;
    font-family: "nunito", sans-serif !important;
  }

  .activeAccount {
    border: 2px solid #fff;
  }

  .sign-out-smal {
    display: flex;
  }
}
