/*estilos basicos*/
.skeleton {
  background: #ddd;
  margin: 10px 0;
  border-radius: 4px;
}

.skeleton.text {
  width: 100%;
  height: 12px;
}

.skeleton.list {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
  border-radius: 50px;
  text-align: center;
}

.skeleton.card {
  width: 150px;
  height: 170px;
  padding: 50px;
  border-radius: 15px;
}

.skeleton.cardp {
  width: 100%;
  height: 100%;
  padding: 50px;
  border-radius: 25px;
}

.skeleton.card-ad {
  width: 600px;
  height: 300px;
  padding: 50px;
  border-radius: 15px;
}

.skeleton.text-card {
  width: 50%;
  height: 12px;
}

.skeleton.text-cardp {
  width: 60%;
  height: 18px;
}

.skeleton.title {
  width: 50%;
  height: 20px;
  margin-bottom: 15px;
}

.skeleton.button {
  width: 35%;
  height: 25px;
  border-radius: 30px;
}

.skeleton.product-img {
  width: 300px;
  height: 300px;
  border-radius: 100%;
}

.skeleton.product-name {
  width: 300px;
  height: 25px;
  margin-top: 20px;
  border-radius: 30px;
}

.skeleton.text-cardproyecto {
  width: 450px;
  height: 25px;
  border-radius: 30px;
  margin-top: -10px;
}

.skeleton.text-cardproyectom {
  width: 350px;
  height: 20px;
  border-radius: 30px;
  margin-top: 10px;
}

.skeleton.product-data {
  width: 750px;
  height: 400px;
  margin-left: 100px;
  border-radius: 30px;
  margin-top: -25px;
}

.skeleton.proyect-data {
  width: 100%;
  height: 350px;
  margin-top: 50px;
  border-radius: 10px !important;
}
.skeleton.proyect-data2 {
  margin-top: 50px;
  width: 100%;
  height: 350px;
  border-radius: 10px !important;
}

.skeleton-wrapper {
  margin-bottom: 15px;
  border-radius: 30px;
  padding: 10px 10px;
  position: relative;
  overflow: hidden;
}
.skeleton-wrapper-card {
  margin-top: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 35px;
  border-radius: 30px;
  padding: 10px 10px;
  position: relative;
  overflow: hidden;
}

.skeleton-wrapper-card-ad {
  margin-top: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 35px;
  border-radius: 30px;
  padding: 10px 10px;
  position: relative;
  overflow: hidden;
}

.skeleton-wrapper-card.light {
  background: #f2f2f2;
}

.skeleton-wrapper-Product {
  margin-top: -225px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 35px;
  border-radius: 30px;
  padding: 10px 10px;
  position: relative;
  overflow: hidden;
  margin-left: 10px;
  width: 100%;
}

.skeleton-wrapper-Proyect {
  margin-top: -225px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 35px;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #f2f2f2;
}

.productoskeleton {
  margin-top: 30px;
  background-color: #ddd;
}

.skeleton-wrapper-Product.light {
  background: #f2f2f2;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 25px;
  padding-right: 25px;
}

.skeleton-wrapper.light {
  background: #f2f2f2;
}

.product-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.proyect-container {
  padding-top: 250px;
  background-color: #b0b0b0;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: 250px;
  gap: 20px;
  width: 100%;
  margin-bottom: 200px;
}

.producto-skeleton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5px;
}
/*Animaciones*/

.shimmer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: cargando 2s infinite;
}

.shimmer {
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  transform: skewX(-20deg);
  box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
}

@keyframes cargando {
  0% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(-60%);
  }
  100% {
    transform: translateX(150%);
  }
}

@media all and (max-width: 968px) {
  .skeleton-wrapper {
    margin-bottom: 15px;
    border-radius: 30px;
    padding: 0px 0px !important;
    padding-left: 1px;
    padding-right: 1px;
    position: relative;
    overflow: hidden;
  }

  .skeleton-wrapper-Product.light {
    background: #f2f2f2;
    padding: 25px;
    padding-top: 50px;
  }

  .skeleton.list {
    width: 100%;
    height: 5px !important;
    border-radius: 5px !important;
  }

  .skeleton.card {
    width: 190px;
    height: 200px;
    padding: 50px;
  }
  .skeleton.button {
    width: 20%;
    height: 15px;
    border-radius: 30px;
  }

  .skeleton-wrapper-Product {
    margin-top: -200px;
    display: flex;
    margin-bottom: 0px;
    border-radius: 30px;
    padding: 0px;
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .product-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }

  .skeleton.product-img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    margin-bottom: 25px !important;
  }

  .skeleton.product-data {
    width: 275px;
    height: 150px;
    margin-left: 0px;
    border-radius: 30px;
    margin-top: 20px;
  }

  .skeleton.product-name {
    width: 300px;
    height: 15px;
    margin-top: 30px;
    border-radius: 30px;
  }

  .skeleton.proyect-data {
    width: 100%;
    height: 250px;
    margin-top: 50px;
  }
  .skeleton.proyect-data2 {
    margin-top: -5px !important;
    width: 100%;
    height: 250px;
    margin-left: 50px !important;
    margin-right: 50px !important;
    margin-bottom: -30px !important;
  }

  .proyect-container {
    padding-top: 250px;
    background-color: #b0b0b0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 80px;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
