.Overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999 !important;
}

.Contenedor-modal {
  width: 750px;
  min-height: 300px;
  background: white;
  position: relative;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
}

.Captcha {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  width: 100% !important;
  padding-bottom: 10px;
}

.Encabezado-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
}

.Encabezado-modal h3 {
  font-weight: 500;
  font-size: 15px;
  color: #808080;
}

.BotonCerrar {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #28a745;
}

.BotonCerrar:hover {
  cursor: pointer;
}

.contenido-form {
  width: 100%;
  min-height: 200px;
}

.contenido-form h1 {
  font-size: 15px !important;
  font-weight: 400;
  color: #1b1b1b;
  margin-top: -10px;
}

.contenido-form p {
  color: #808080;
  font-weight: 200;
  font-size: 10px;
  margin-bottom: -10px;
  margin-top: 5px;
}

.Contenido {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.Contenido label {
  font-size: 12px !important;
  color: #28a745;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 5px;
}

.Contenido input[type="text"] {
  font-size: 12px !important;
  color: black;
  font-weight: 400;
  border-radius: 30px;
}

.Contenido input[type="text"]:focus {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  border: 2px solid #28a745;
  font-weight: 400;
}

.Contenido input[type="email"] {
  font-size: 12px !important;
  color: black;
  font-weight: 400;
  border-radius: 30px;
}

.Contenido input[type="email"]:focus {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  border: 2px solid #28a745;
  font-weight: 400;
}

.Contenido textarea {
  resize: none;
  font-size: 12px !important;
  color: black;
  font-weight: 400;
}

.Contenido textarea:focus {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  border: 2px solid #28a745;
  font-weight: 400;
}

.Contenido input[type="submit"] {
  border-radius: 30px;
  font-size: 12px !important;
  width: 300px;
  margin-top: 20px !important;
  background: #28a745 !important;
  border: none !important;
  transition: ease-in-out 0.5s;
}

.Contenido input[type="submit"]:hover {
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.enviar-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
}

.invalid {
  color: red;
  font-size: 10px;
  font-weight: 600;
  text-align: left;
}

.error-borde {
  border: 2px solid red !important;
  animation: shake 300ms;
}

.error-borde-login{
  border-bottom: 2px solid red !important;
  animation: shake 300ms;
}

.contact-footer {
  width: 100%;
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-template-rows: 100%;
  padding: 0 !important;
  height: 18px;
  margin-top: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 25px;
  justify-content: center;
  margin-bottom: -10px;
}

.contact-footer a {
  color: #28a745 !important;
  text-decoration: none;
  font-size: 12px;
}

.contact-footer p {
  color: #28a745 !important;
  font-size: 12px !important;
  text-align: center;
}

@media all and (max-width: 968px) {
  .contact-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 !important;
    height: 10px;
    margin-top: 5px;
    padding-top: 6px !important;
    padding-bottom: 2px !important;
    margin-bottom: -10px;
  }

  .contact-footer a {
    text-decoration: none;
  }

  .contact-footer p {
    color: #28a745 !important;
    font-size: 10px !important;
    text-align: center;
  }

  .Contenido {
    overflow-y: auto !important;
  }

  .invalid {
    color: red;
    font-size: 8px;
    font-weight: 600;
  }
}

@keyframes shake {
  25% {
    transform: translateX(4px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(4px);
  }
}

