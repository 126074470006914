.select-menu .select-btn {
  display: flex;
  height: 30px;
  background: #fff;
  color: black;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 400;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: -125px;
  position: relative;
  margin-top: -15px;
  cursor: pointer;
}

.select-menu .options {
  position: absolute;
  padding: 10px;
  margin-top: 130px;
  background: #fff;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  z-index: 2;
}

.options .option {
  display: flex;
  height: 25px;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
}

.option:hover {
  background: #ddd;
  color: #1b1b1b !important;
}

.optionActive {
  background: #ddd;
  color: #1b1b1b !important;
}

.option .option-text {
  font-size: 15px;
  font-weight: 400;
}

@media all and (max-width: 968px) {
  .select-menu .select-btn {
    display: flex;
    height: 30px;
    background: #fff;
    color: black;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 400;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: -125px;
    position: relative;
    margin-top: -15px;
    cursor: pointer;
  }

  .select-menu .options {
    position: absolute;
    padding: 10px;
    margin-top: 130px;
    background: #fff;
    z-index: 1;
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    display: block;
  }

  .sBtn-text {
    font-size: 10px !important;
  }

  .option .option-text {
    font-size: 10px;
    font-weight: 400;
  }
}
