.container-projects {
  width: 100%;
  height: 100%;
  padding-top: 110px !important;
}

.projects {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  margin-bottom: 30px !important;
  line-height: 15px;
}

.projects h1 {
  font-size: 45px !important;
  margin-bottom: 0px;
  color: #28a745;
  font-weight: bold;
}

.projects p {
  font-size: 18px;
  font-weight: 400;
  color: black !important;
}

.projects p span{
  font-size: 18px;
  color: #28a745;
  font-weight: 600;
  margin-left: 2px;
}


.Finder {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  margin-top: -30px ; 
}

.listado-proyectos {
  display: grid;
  gap: 1rem;
  grid-auto-rows: 18rem;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  margin-bottom: 60px !important;
  margin-top: -20px !important;
  gap: 10px 10px;
}

.categoriap{
  display: block !important;
  margin-top: -5px;
  color: #808088;
  font-weight: 400;
  margin-bottom: -15px;

}

.Galeriap {
  display: block !important;
  background: none;
}

.Galeriap li {
  background: white;
  border-radius: 30px;
  text-decoration: none;
  color: #808088;
  text-align: center;
  cursor: pointer;
}

.Galeriap li p {
  padding: 5px;
  font-size: 15px;
  font-weight: 600;
  color: #1b1b1b;
}

.chipers{
  padding-top: 15px !important;
}

.Finder-2{
  margin-top: -30px;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: bolder;
}


@media all and (max-width: 968px) {
  .container-projects {
    width: 100%;
    height: 100%;
    padding-top: 80px !important;
  }

  .Finder-2{
    margin-top: -45px;
    margin-bottom: 30px;
    font-size: 15px;
    font-weight: bolder;
  }

  .projects h1 {
    font-size: 25px !important;
    margin-bottom: -5px;
    color: #28a745;
    font-weight: bold;
  }

  .projects p {
    margin-top: 3px !important;
    font-size: 15px;
    font-weight: 400;
    color: black !important;
    margin-bottom: 5px !important;
  }
  
  .projects p span{
    font-size: 15px;
    color: #28a745;
    font-weight: 600;
  }

  .projects {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    padding-bottom: 25px !important;
    margin-top: -5px !important;
    margin-bottom: 10px !important;
  }

  .chipers {
    width: 100%;
    height: 2rem;
    padding-top: 0px !important;
    margin-top: 75px !important;
    margin-bottom: 50px !important;
  }

  .filterp-grande{
    display: none;
  }

  .categoriap{
    display: none !important;
    margin-top: -5px;
    color: #808088;
    font-weight: 400;
  }

  .Finder{
    padding-top: 100px;
    padding-bottom: 0px !important;
    margin-bottom: -95px !important;
  }
  .listado-proyectos {
    display: grid;
    gap: 1rem;
    grid-auto-rows: 18rem;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    margin-bottom: 60px !important;
    margin-top: 30px !important;
    gap: 10px 10px;
    padding-top: 80px;
  }
}
