.container-Administracion {
  width: 100%;
  height: 100%;
  padding-top: 8rem;
  justify-content: center;
  background-color: white;
}

.card-Administracion-contenedor {
  border-radius: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  height: 100%;
  width: 100%;
}

.container-form-user {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1.5rem;
  width: 100%;
}

.modal-size {
  width: 50vw;
}

.footer-modal {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  bottom: 0;
  top: 0;
  margin-top: 2rem;
  gap: 2rem;
}

.texto-ficha {
  cursor: pointer;
}

.texto-ficha:hover {
  cursor: pointer;
}


/* Apartado para la galeria de los proyectos */

.section-galeria {
  padding: 2rem 0;
}

.section-galeria label {
  margin: -30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dotted black;
  border-radius: 20px;
  width: 20rem;
  height: 10rem;
  cursor: pointer;
  font-size: large;
}

.section-galeria input {
  display: none;
}

@media all and (max-width: 968px) {
  .modal-size {
    width: 90% !important;
  }
}