.contenedor-notfound {
  width: 100%;
  height: 100vh;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.contenedor-notfound h1 {
  font-size: 18px;
  font-weight: 400;
  color: #808080;
  margin-top: -15px;
}

.contenedor-notfound p {
  font-size: 90px;
  font-weight: 700;
  color: black;
}

.contenedor-notfound span {
  margin-bottom: 15px;
  font-size: 15px;
  color: #1b1b11;
  font-weight: 400;
}

.contenedor-notfound a {
  text-decoration: none;
  background: white;
  color: #1b1b11;
  border-radius: 30px;
  font-size: 15px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 30px;
  padding-right: 30px;
  transition: ease-in 0.2s;
}
.contenedor-notfound a:hover {
  cursor: pointer;
  background: #1b1b11;
  color: white;
}

.img-not {
  padding-bottom: 30px;
  width: 350px;
  height: 200px;
}

@media all and (max-width: 968px) {
  .contenedor-notfound {
    width: 100%;
    height: 100%;
    padding-top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 200px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .contenedor-notfound p{
    font-size: 80px !important;
  }
  .contenedor-notfound h1 {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 15px;
  }
  .contenedor-notfound span {
    font-size: 15px;
    font-weight: 400;
    padding-bottom: 5px;
  }

  .img-not {
    padding-bottom: 30px;
    width: 250px;
    height: 150px;
    display: none;
  }
}
